<template>
  <div v-if="visible" id="showimgid" @click="closeAction">
  <el-dialog
    class="tg-dialog-classic-video"
    :visible="visible"
    :close-on-click-modal="true"
    :destroy-on-close="true"
    @close="closeAction"
    :append-to-body="true"
    :show-close="true"
    @click="closeAction"
  >
    <div>
    <div class="finance-certificate-img-box-list" @click="closeAction">
    </div>
    <div class="imgdiv">
      <video-player
        class="my_video video-player vjs-custom-skin"
        height="100%"
        width="100%"
        ref="videoPlayerRef"
        :playsline="false"
        :options="playerOptions"
        controls
      ></video-player>
    </div>
    </div>
  </el-dialog>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";

export default {
  name: "show-big-video",
  props: {
    videoUrl: {
      type: String,
      default:'',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    videoPlayer
  },
  data() {
    return {
      isFirst: true
    }
  },
  computed: {
    // 预计佣金
    playerOptions: function () {
      return  {
        language: 'zh-CN',
        autoplay: true,
        muted: true,
        sources: [{
          type: 'video/mp4',
          src: this.videoUrl
        }]
      };
    }
  },
  methods: {
    /*
     @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event)"
          @ended="onPlayerEnded($event)"
          @waiting="onPlayerWaiting($event)"
          @playing="onPlayerPlaying($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @canplay="onPlayerCanplay($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @statechanged="playerStateChanged($event)"
          @ready="playerReadied"
    // 播放回调
    onPlayerPlay(player) {},

    // 暂停回调
    onPlayerPause(player) {
    },

    // 视频播完回调
    onPlayerEnded($event) {},

    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting($event) {},

    // 已开始播放回调
    onPlayerPlaying($event) {},

    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata($event) {},

    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate($event) {},

    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
    },

    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
    },

    //播放状态改变回调
    playerStateChanged(playerCurrentState) {},

    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {},*/
    closeAction() {
      this.isFirst=true;
      this.$emit('closeFinanceCertificateAction');
    },
  }
}
</script>

<style lang="less">
.tg-dialog-classic-video {
  cursor: pointer;
  z-index: 3000 !important;
  .finance-certificate-img-box-list {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    margin: auto;
    padding-top: 80px !important;
    position: fixed;
    top: 0px;
    /* margin-top: 10vh !important;
     margin-left: -2vh !important;*/
  }

  .imgdiv {
    margin: auto;
    top: 80px;
    margin-left: calc(50vw - 50vh);
    position: fixed;
    width: calc(100vh) !important;
    //height: 80vh !important;
  }
}
.video-js {
  > div, button {
    display: none !important;
  }
}

.video-js .vjs-play-progress .vjs-time-tooltip{
  display: none !important;
}
.my_video {
  cursor: pointer;
  width: calc(100vh) !important;
  max-height: calc(100vh - 160px);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .video-js {
    max-width: calc(100vh) !important;
    max-height: calc(100vh - 160px) !important; //我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可
    display: flex;
    align-items: center;
    justify-content: center;
  }
  video {
    max-width: calc(100vh) !important;
    max-height: calc(100vh - 160px) !important; //我的容器高度设置的是100vh-95px，你们根据你们容器高度设置视频高度即可
    object-fit:fill; //消除两边留白
  }
}
.video-js .vjs-icon-placeholder {
  width: 80%;
  height: 80%;
  display: block;
}
.videoPlayer /deep/ .el-dialog,
.el-pager li {
  background: none !important;
  margin-top: 6vh !important;
}
.my-img:hover{
  cursor:pointer;
}

</style>
