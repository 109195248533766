/*
 * @Author: 肖槿
 * @Date: 2020-06-12 18:31:09
 * @Description: 小店管理全局过滤
 * @LastEditors: 矢车
 * @LastEditTime: 2020-08-07 21:03:48
 * @FilePath: \s2b-admin\src\filters\shop.js
 */
import moment from 'moment'
import hsArea from '@/assets/hangzhou.json'
export const shopMargin = (v, list) => {
  const n = list.find(item => item.value === v)
  return n ? n.label : v
}

export const timeFormat = (v, type) => {
  if(v===1) {
    return ''
  }
  if (!v) return '--'
  v = String(v).length === 10 ? v * 1000 : v
  return moment(v).format(type)
}

export const certificationClass = (v) => {
  switch (v) {
    case 1: // 未认证
      return 'certified'
    case 2: // 认证中
      return 'certifing'
    case 3: // 已认证
      return 'certifiy'
    case 4: // 认证失败
      return 'certifiy-fail'
    default:
      return 'certifiy-fail'
  }
}

// 类目过滤
export const categoryFilter = (v, list) => {
  const n = list.find(item => item.id === v)
  return n ? n.name : v
}

// 金额千分位
export const thousandsFormat = (v) => {
  if (!v || v === 0) return '0.00'
  return (Number(v) / 100).toFixed(2).toLocaleString()
}

// 匹配行政区划
export const division = (v) => {
  if (!v || v===1) return ''
  const val = String(v)
  const o = val.substr(0, 2) + '0000'
  const p = val.substr(0, 4) + '00'
  if (val.substr(2) === '0000') {
    const prov = hsArea.find(n => n.value === v)
    return prov.label
  } else if (val.substr(4) === '00') {
    const prov = hsArea.find(n => String(n.value) === o)
    const city = prov.children.find(n => String(n.value) === p)
    return city.label
  } else {
    const prov = hsArea.find(n => String(n.value) === o)
    const city = prov.children.find(n => String(n.value) === p)
    const county = city.children.find(n => n.value === v)
    return county.label
  }
}



/**
 * @Author: 矢车
 * @Date: 2020-08-05 12:45:35
 * @Description: 时间戳转为时间
 */
export const dateFilter = (timpStamp) => {

  if (!timpStamp) {
    return ' '
  }
  // 时间戳转为毫秒格式
  timpStamp += '000';
  const date = new Date(parseInt(timpStamp)) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000

  const dateNumFun = (num) => num < 10 ? `0${num}` : num // 使用箭头函数和三目运算以及es6字符串的简单操作。因为只有一个操作不需要{} ，目的就是数字小于10，例如9那么就加上一个0，变成09，否则就返回本身。

  const [Y, M, D, h, m, s] = [
    date.getFullYear(),
    dateNumFun(date.getMonth() + 1),
    dateNumFun(date.getDate()),
    dateNumFun(date.getHours()),
    dateNumFun(date.getMinutes()),
    dateNumFun(date.getSeconds())
  ]
  return `${Y}-${M}-${D} ${h}:${m}:${s}` // 一定要注意是反引号，否则无效。
}
