<!--
 * @Description: 入口页面
 * @Date: 2019-12-21 16:58:39
 * @LastEditTime : 2020-01-04 14:06:21
 * @LastEditors  : 白青
 -->
<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { useNetwork, useVersion } from '@/pages/global/app.global';
import Index from './pages/index';

export default defineComponent({
  name: 'app',
  components: {
    Index,
  },
  setup() {
    useNetwork();

    return { ...useVersion() };
  },
});
</script>

<style lang="less">
@import './styles/components.less';
@import './styles/pages.less';
body {
  .ant-table-body {
    //样式穿透
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
}
</style>
