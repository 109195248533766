<!--
 * @Description: index.vue
 * @Date: 2019-12-21 17:10:55
 * @LastEditTime: 2020-07-30 10:35:46
 * @LastEditors: 胡椒
 -->
<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      collapsed: false,
      locale: zhCN
    }
  }
}
</script>
<style lang="less">
#components-layout-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
  overflow: hidden;
  span {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    line-height: 32px;
    color: #fff;
  }
}

#components-layout-custom-trigger {
  position: absolute;
  width: 100%;
  height: 100%;
}

.fixed-layout-header {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  width: calc(100% - 200px);
  position: fixed;
  top: 0;
  height: 50px;
  line-height: 50px;
  left: 200px;
  box-shadow:0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  &.header-menu-fold {
    width: calc(100% - 80px);
    left: 80px;
  }
}
/*.layout-content {
  margin-top: 30px !important;
}*/
</style>
