var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"finance-certificate-dialog-list",attrs:{"id":"showimgid"},on:{"click":_vm.closeAction}},[_c('el-dialog',{staticClass:"tg-dialog-classic-list",attrs:{"visible":_vm.visible,"close-on-click-modal":true,"destroy-on-close":true,"append-to-body":true,"show-close":true},on:{"close":_vm.closeAction,"click":_vm.closeAction}},[_c('div',{staticClass:"finance-certificate-img-box-list",on:{"click":_vm.closeAction}},[_c('div',{staticClass:"list-div"},[(_vm.listUrl.length > 1)?_c('a-icon',{style:({
            color: _vm.new_select_index === 0 ? 'rgba(255,255,255,0.3)' : 'rgba(255,255,255,0.7)',
          }),attrs:{"type":"left-circle"},on:{"click":function($event){$event.stopPropagation();return _vm.changeImgClick(-1)},"dblclick":function($event){$event.stopPropagation();return _vm.dbclick.apply(null, arguments)}}}):_vm._e(),_c('div',{staticClass:"imgdiv"},[_c('img',{staticClass:"img-url",attrs:{"src":_vm.new_img_url},on:{"error":function (e) {
                e.target.src = _vm.defaultImg;
              }}})]),(_vm.listUrl.length > 1)?_c('a-icon',{style:({
            color:
              _vm.new_select_index === _vm.listUrl.length - 1
                ? 'rgba(255,255,255,0.3)'
                : 'rgba(255,255,255,0.7)',
          }),attrs:{"type":"right-circle"},on:{"click":function($event){$event.stopPropagation();return _vm.changeImgClick(1)},"dblclick":function($event){$event.stopPropagation();return _vm.dbclick.apply(null, arguments)}}}):_vm._e()],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }