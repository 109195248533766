/*
 * @Author: 肖槿
 * @Date: 2020-06-12 18:26:47
 * @Description: 小店管理过滤
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-08-11 14:46:41
 * @FilePath: \s2b-admin\src\filters\index.js
 */

import {
  shopMargin,
  timeFormat,
  certificationClass,
  categoryFilter,
  thousandsFormat,
  division,
} from './shop';
import {
  dftValue,
  expressStatus,
  scheduleStatus,
  liveType,
  channel,
  coopMode,
  pidAttr,
  goodsChannel,
} from '@/utils/index';

let list = [
  { name: 'shopMargin', fn: shopMargin },
  { name: 'timeFormat', fn: timeFormat },
  { name: 'certificationClass', fn: certificationClass },
  { name: 'categoryFilter', fn: categoryFilter },
  { name: 'thousandsFormat', fn: thousandsFormat },
  { name: 'division', fn: division },
  { name: 'dftValueFilter', fn: dftValue },
  { name: 'expressStatusFilter', fn: expressStatus },
  { name: 'scheduleStatusFilter', fn: scheduleStatus },
  { name: 'liveTypeFilter', fn: liveType },
  { name: 'channelFilter', fn: channel },
  { name: 'coopModeFilter', fn: coopMode },
  { name: 'pidAttrFilter', fn: pidAttr },
  { name: 'goodsChannelFilter', fn: goodsChannel },
];
function install(Vue) {
  list.forEach((item) => {
    Vue.filter(item.name, item.fn);
  });
}

export default { install };
