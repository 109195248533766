<template>
  <div
    style="width: 54px; height: 54px; display: block"
    :style="{ width: imgWidth + 'px', height: imgWidth + 'px' }"
  >
    <!-- 视频   -->
    <template v-if="imgUrl.substring(imgUrl.length - 3) == 'mp4'">
      <div
        class="video-div"
        @click="showImgClick"
        size="small"
        :style="{ width: imgWidth + 'px', height: imgWidth + 'px' }"
      >
        <video
          disable
          id="my-player"
          class="video-js"
          :style="{ width: imgWidth + 'px', height: imgWidth + 'px' }"
          preload="auto"
          :width="imgWidth"
          :height="imgWidth"
        >
          <source :src="imgUrl" type="video/mp4" />
        </video>
      </div>
      <showBigVideo
        v-if="visible"
        :visible="visible"
        :videoUrl="imgUrl"
        @closeFinanceCertificateAction="visible = false"
      />
    </template>
    <!--  图片     -->
    <template v-else>
      <!--       <el-image  @click="showImgClick"
                 v-if="imgUrl"
                 lazy
                 class="el-img"
                 :style="{width: imgWidth + 'px', height: imgWidth + 'px'}"
                 :src="imgUrl"
                 fit="fill"
       />-->
      <img
        v-if="imgUrl"
        @click="showImgClick"
        lazy
        fit="fill"
        :src="imgUrl"
        :preview-src-list="[imgUrl]"
        :style="{ width: imgWidth + 'px', height: imgWidth + 'px' }"
        class="el-img"
        @error="
          e => {
            e.target.src = defaultImg;
          }
        "
      />
      <showListBigImg
        v-if="visible"
        :list-url="listUrl"
        :select-index="selectIndex"
        :visible="visible"
        :imgUrl="imgUrl"
        @closeFinanceCertificateAction="visible = false"
      />
    </template>
  </div>
</template>
<script>
import showListBigImg from './show-list-big-img';
import showBigVideo from './show-big-video';
import defaultImg from '@/assets/icon-kuran-default.jpg';
export default {
  name: 'my-image',
  props: {
    title: {
      type: String,
      default: '',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    imgWidth: {
      type: Number,
      default: 54,
    },
    listUrl: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectIndex: {
      type: Number,
      default: 0,
    },
  },
  components: {
    showListBigImg,
    showBigVideo,
  },
  data() {
    return {
      visible: false,
      isFirst: true,
      defaultImg,
    };
  },
  methods: {
    showImgClick() {
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.video-div {
  overflow: hidden;
  cursor: pointer;
  .video-js {
    border: 1px solid #f6f6f6;
  }
}
.el-img {
  cursor: pointer;
  width: 54px;
  height: 54px;
  //border:1px solid #f6f6f6;
  .el-image__error {
    padding-top: 28%;
    height: 100%;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #999999;
  }
  /deep/ .el-image__error {
    padding-top: 28%;
    height: 100%;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #999999;
  }
  /deep/ img {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
