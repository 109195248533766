<template>
  <div v-if="visible" class="finance-certificate-dialog-list" id="showimgid" @click="closeAction">
    <el-dialog
      class="tg-dialog-classic-list"
      :visible="visible"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="closeAction"
      :append-to-body="true"
      :show-close="true"
      @click="closeAction"
    >
      <div class="finance-certificate-img-box-list" @click="closeAction">
        <div class="list-div">
          <a-icon
            v-if="listUrl.length > 1"
            :style="{
              color: new_select_index === 0 ? 'rgba(255,255,255,0.3)' : 'rgba(255,255,255,0.7)',
            }"
            type="left-circle"
            @click.stop="changeImgClick(-1)"
            @dblclick.stop="dbclick"
          />
          <div class="imgdiv">
            <img
              :src="new_img_url"
              class="img-url"
              @error="
                e => {
                  e.target.src = defaultImg;
                }
              "
            />
          </div>
          <a-icon
            v-if="listUrl.length > 1"
            :style="{
              color:
                new_select_index === listUrl.length - 1
                  ? 'rgba(255,255,255,0.3)'
                  : 'rgba(255,255,255,0.7)',
            }"
            type="right-circle"
            @click.stop="changeImgClick(1)"
            @dblclick.stop="dbclick"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import defaultImg from '@/assets/icon-kuran-default.jpg';
import { defineComponent, ref } from '@vue/composition-api';
export default defineComponent({
  name: 'show-list-big-img',
  props: {
    imgUrl: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    listUrl: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFirst: true,
      defaultImg,
    };
  },
  setup(props) {
    const new_img_url = ref(props.imgUrl);
    const new_select_index = ref(props.selectIndex);
    const changeImgClick = index => {
      if (
        (new_select_index.value === 0 && index === -1) ||
        (new_select_index.value === props.listUrl.length - 1 && index === 1)
      ) {
        return;
      }
      new_img_url.value = props.listUrl[new_select_index.value + index];
      new_select_index.value = new_select_index.value + index;
    };
    const dbclick = () => {};
    return {
      dbclick,
      new_select_index,
      changeImgClick,
      new_img_url,
    };
  },
  methods: {
    getElementsByClassName(className) {
      var classElements = [];
      var allElements = document.getElementsByTagName('*');
      for (var i = 0; i < allElements.length; i++) {
        if (allElements[i].className == className) {
          classElements[classElements.length] = allElements[i];
        }
      }
      return classElements;
    },
    closeAction() {
      this.isFirst = true;
      this.$emit('closeFinanceCertificateAction');
    },
  },
});
</script>

<style scoped lang="less">
.tg-dialog-classic-list {
  cursor: pointer;
  user-select: none;
  .finance-certificate-img-box-list {
    width: 100vw;
    height: 100vh;
    background-color: transparent !important;
    margin: auto;
    padding-top: 5vh !important;
    position: fixed;
    top: 0px;
    /* margin-top: 10vh !important;
     margin-left: -2vh !important;*/
    .imgdiv {
      user-select: none;
      margin: auto 32px;
      width: 90vh !important;
      height: 90vh !important;
      display: flex;
      align-items: center;
    }
    .list-div {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      .anticon {
        color: rgba(255, 255, 255, 0.7);
        font-size: 36px;
        user-select: none;
      }
    }
    .img-url {
      margin: auto;
      max-width: 90vh !important;
      max-height: 90vh !important;
      /*border: 1px solid #d8d8d8;*/
      border-radius: 4px;
      user-select: none;
    }
  }
  /deep/ .el-dialog {
    border: none !important;
    margin-top: 0vh !important;
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    .el-dialog__header {
      height: 0px !important;
    }
    .el-dialog__body {
      width: 100vw !important;
      margin: auto;
      border: none !important;
      /*  *, *::before, *::after {
           -webkit-box-sizing: border-box;
           box-sizing: border-box;
         }*/
    }
  }
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
