/*
 * @Description: 小店商品类目
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-04-01 14:00:40
 * @LastEditTime: 2020-11-05 10:08:43
 */

// 商品渠道
export const goodsChannelList = [
    {'label': '淘宝联盟', 'value': 1},
    {'label': '抖音小店', 'value': 2},
]
// 商品来源选择项
export const sourceOptions =[
    { label: "自主招商", value: 1 },
    // { label: "淘宝联盟", value: 2 },
    // { label: "大淘客", value: 3 },
    { label: "抖音小店", value: 4 },
    { label: "招商系统", value: 5 },
]

/**
 * @Author: 矢车
 * @Date: 2020-09-22 11:56:50
 * @Description: 寄样模式枚举
 */
export const sendSampleOptions = [
  { label: '免费寄样', value: 1, color: 'green' },
  { label: '站外采买', value: 0, color: 'red' },
  { label: '站内采买', value: 2, color: 'red' },
]

/**
 * @Author: 矢车
 * @Date: 2020-09-22 11:56:50
 * @Description: 寄样模式类型2枚举
 */
export const sendSampleTwoOptions = [
  { label: '免费寄样', value: 1, color: 'green' },
  { label: '站外采买', value: 0, color: 'red' },
]

/**
 * @Author: 矢车
 * @Date: 2020-09-22 11:56:50
 * @Description: 寄样模式类型另外2枚举
 */
export const sendSampleTwoOtherOptions = [
  { label: '免费寄样', value: 0, color: 'green' },
  { label: '站内采买', value: 1, color: 'red' },
]

export const sendSampleTwoOtherOptionsOther = [
  { label: '免费寄样', value: 0, color: 'green' },
  { label: '站内采买', value: 1, color: 'red' },
]


/**
 * @Author: 矢车
 * @Date: 2020-10-15 19:47:07
 * @Description: 寄样模式详情支付状态枚举
 */
export const payStatusOptions = [
  { label: '待支付', value: 1, color: 'red' },
  { label: '已支付', value: 2, color: 'green' },
  { label: '已关闭', value: 6, color: 'red' },
  { label: '已退款', value: 5, color: 'green' },
]


/**
 * @Author: 矢车
 * @Date: 2020-10-15 19:47:07
 * @Description: 寄样模式商品类型
 */
export const goodsTypeOptions = [
  { label: '淘宝', value: 0, src:'https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_taobao%402x.png' },
  { label: '天猫', value: 1, src:'https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_taobao%402x.png' },
  { label: '抖音小店', value: 2, src:'https://oss.zheyi360.com/app-image/weapp/images/detail/new_goods_type_douyin%402x.png' },
]
