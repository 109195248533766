/*
 * @Description: 常量
 * @Date: 2019-12-24 11:03:26
 * @LastEditTime: 2020-08-12 09:34:14
 * @LastEditors: 胡椒
 */
// 验证码类型
// (0, '通用'), (1, '登录'), (2, '注册'), (3, '找回密码'), (4, '实名制申请')
export const CODE_TYPE = {
  NORMAL: 0,
  LOGIN: 1,
  REGISTER: 2,
  FIND_PWD: 3,
  VERIFIED: 4
}

// 用户状态
// (0, '审核中'), (1, "审核通过"), (2, '未通过')
export const USER_STATUS = {
  UNDER_REVIEW: {
    VALUE: 0,
    TEXT: '审核中'
  },
  PASSED: {
    VALUE: 1,
    TEXT: '通过'
  },
  NOT_PASS: {
    VALUE: 2,
    TEXT: '拒绝'
  }
}

// 设备类型
export const DEVICE_TYPE = {
  UNKNOWN: {
    VALUE: 0,
    TEXT: '未知'
  },
  WEAPP: {
    VALUE: 1,
    TEXT: '小程序'
  },
  ANDROID: {
    VALUE: 2,
    TEXT: '安卓'
  },
  IOS: {
    VALUE: 3,
    TEXT: '苹果'
  }
}

// 实名认证状态 (0, '未申请实名制认证'), (1, '实名制认证中'), (2, '实名制认证失败'), (3, '实名制认证成功')
export const REAL_NAME_APPROVAL_STATUS = {
  NOT_APPLY: {
    VALUE: 0,
    TEXT: '未申请实名制认证'
  },
  APPROVING: {
    VALUE: 1,
    TEXT: '认证中'
  },
  APPROVAL_FAILED: {
    VALUE: 2,
    TEXT: '驳回'
  },
  APPROVAL_SUCCESS: {
    VALUE: 3,
    TEXT: '通过'
  },
}

// 性别
export const GENDER_CODE = {
  MALE: {
    VALUE: 1,
    TEXT: '男'
  },
  FEMALE: {
    VALUE: 2,
    TEXT: '女'
  }
}

// 提现记录状态 (1, '待处理'), (2, '提现成功'), (3, '驳回申请')
export const WITHDRAW_STATUS = {
  PROCESSING: {
    VALUE: 1,
    TEXT: '待处理'
  },
  PAID: {
    VALUE: 2,
    TEXT: '提现成功'
  },
  REJECT: {
    VALUE: 3,
    TEXT: '驳回申请'
  }
}

// 拼播状态
export const PINBO_STATUS = {
  '1': '未开始',
  '2': '进行中',
  '3': '拼团成功',
  '4': '已中止',
  '5': '成团审核',
  '6': '拼团失败',
  '7': '已暂停'
}

// 渠道
export const CHANNEL = {
  '1': '抖音',
  '2': '淘宝',
}

// 渠道列表项
export const channelOption = [{
    'label': '抖音',
    'value': 1
  },
  {
    'label': '淘宝',
    'value': 2
  },
]

// user level
export const userLevelOption = [{
    'label': 'LV1',
    'value': 1
  },
  {
    'label': 'LV2',
    'value': 2
  },
  {
    'label': 'LV3',
    'value': 3
  },
  {
    'label': 'LV4',
    'value': 4
  },
  {
    'label': 'LV5',
    'value': 5
  },
]

// 淘宝等级
export const taobaoLevelOption = [{
    'label': 'V1',
    'value': 1
  },
  {
    'label': 'V2',
    'value': 2
  },
  {
    'label': 'V3',
    'value': 3
  },
  {
    'label': 'V4',
    'value': 4
  },
  {
    'label': 'V5',
    'value': 5
  }
]

// 抖音等级
export const douyinLevelOption = [{
    'label': 'LV1',
    'value': 1
  },
  {
    'label': 'LV2',
    'value': 2
  },
  {
    'label': 'LV3',
    'value': 3
  },
  {
    'label': 'LV4',
    'value': 4
  },
  {
    'label': 'LV5',
    'value': 5
  },
  {
    'label': 'LV6',
    'value': 6
  },
  {
    'label': 'LV7',
    'value': 7
  },
]

// 图表颜色序列
export const chartColorOption = [
  // '#4785FF', // 蓝   （优先）
  // '#e24c4d', // 深红 （优先）
  // '#ff9700', // 黄   （优先）
  // '#008ae2', // 天蓝 （优先）
  // '#fe5943', // 橙   （优先）
  // '#f6b422', // 金黄 （优先）
  // '#8c6cd1', // 紫   （优先）
  // '#1ca37a', // 青   （优先）
  // '#55bd5a', // 绿
  // '#54d970', // 草绿
  // '#ea61a5', // 梅红
  // '#2fe7d8', // 亮蓝

  // '#C247FF', '#AC4CFF', '#9447FF', '#6647ff', '#4757ff',
  // '#4785ff', '#49a1ff', '#47b3ff', '#40cae5', '#3cd6d6',
  // '#3fe0d3', '#3fe0ab', '#3fe082', '#3fe05a', '#4bdb3d',
  // '#45cc39', '#75e03f', '#9de03f', '#b5e03f', '#c5e03f',
  // '#facd46', '#ffa347', '#ff950a', '#ff7547', '#ff4747',
  // '#f54553', '#fa506c', '#ff4c88', '#ff4cb5', '#f549d8',
  // '#f04cff', '#db4cff',

  '#49A1FF','#3CD6D6','#FFA347','#FACD46','#FF4C88',
  '#F549D8','#DB4CFF','#AC4CFF','#45CC39','#9DE03F',
  '#C5E03F','#4785FF','#4757FF'
]

// 企业类型
export const enterpriseType = {
  'EAccountM': '普通企业号',
  'EAccountS': '认证企业号',
  'EAccountK': '品牌企业号',
  '': ''
}

// 性别
export const genderType = {
  '1': '男性',
  '2': '女性',
  '0': '未知',
}

// 合作模式列表项
export const coopModeOptions = [
  { label: '签约主播', value: 1 },
  { label: '机构合作', value: 2 },
  { label: '外部主播', value: 3 },
];

// 淘客PID归属列表项
export const pidAttrOptions = [
  { label: '主播自有', value: 1 },
  { label: '构美发放', value: 2 },
];

// 发货状态options
export const expressStatusOptions = [
  { label: '待发货', value: 1 },
  { label: '已发货', value: 2 },
  { label: '已收货', value: 3 },
  { label: '已取消', value: 4 },
  { label: '待寄回', value: 6 },
  { label: '已寄出', value: 7 },
  { label: '已回收', value: 8 },
];

// 排期状态options
export const scheduleStatusOptions = [
  { label: '待主播申佣', value: 1 },
  { label: '待折一申佣', value: 2 },
  { label: '申佣待处理', value: 3 },
  { label: '待直播', value: 4 },
  { label: '已结束', value: 5 },
  { label: '已取消', value: 6 },
];

// 直播类型options
export const liveTypeOptions = [
  { label: '拼播', value: 1 },
  { label: '单播', value: 2 },
  { label: '专场', value: 3 },
];

// 排期状态
export const SCHEDULE_STATUS = {
  NEED_ANCHOR_APPLY: 1, // 待主播申佣
  NEED_KURAN_APPLY: 2, // 待折一申佣
  WAIT_HANDLE: 3, // 申佣待处理
  WAIT_LIVE: 4, // 待直播
};
