import { defineComponent, h } from '@vue/composition-api';

export default defineComponent({
  name: 'AfInput',
  props: ['format', 'addon-after', 'value'],
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    format_integer(value) {
      return value.replace(/[^\d]/g, '');
    },
    format_number(value) {
      return value.replace(/[^.\d]/g, '');
    },
    format_default(value) {
      return value;
    },
  },
  render() {
    const formatFunc = this[`format_${this.format}`] || this.format_default;
    const $listeners = {
      ...this.$listeners,
      input: event => {
        const formatValue = formatFunc(event.target.value);
        this.$listeners.change(formatValue);
      },
    };
    return h(
      'a-input',
      {
        attrs: this.$attrs,
        on: $listeners,
        scopedSlots: this.$scopedSlots,
        slot: this.slot,
        props: this.$options.propsData,
        domProps: {
          value: this.value,
        },
      },
      [this.$slots.default],
    );
  },
});
