/*
 * @Description: 请求配置
 * @Date: 2019-12-23 14:43:07
 * @LastEditTime: 2020-08-10 18:29:54
 * @LastEditors: 矢车
 */
import axios from 'axios';

import { baseURL } from '../config/index';
import store from '../store/index';
import { message } from 'ant-design-vue';

const instance = axios.create({
  baseURL,
  timeout: 30000,
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    const token = store.getters.getToken;
    config.headers = {
      Authorization: token ? 'jwt ' + token : undefined,
    };
    return config;
  },
  function (error) {
    message.error(`发送请求出错 msg:${error.message}`);
    return Promise.resolve(error);
  },
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    if (!response.data.success) {
      if(response.config.throwMessage !== false){
        if (response.data.message) {
          message.error(response.data.message);
        }
      }
      if (response.data.error_code === 3001) {
        store.commit('logout');
        location.href = '/login';
      }
    }
    return Promise.resolve({ err: null, res: response.data });
  },
  function (error) {
    message.error(`服务器开小差了~ msg:${error.message}`);
    return Promise.resolve({ err: error, res: null });
  },
);

export default instance;
