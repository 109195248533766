/*
 * @Description: User store module
 * @Date: 2019-12-23 12:25:26
 * @LastEditTime: 2020-04-07 15:23:09
 * @LastEditors: 前胡
 */
import {
  getUserRight
} from "@/service/admin";
// import {rightsGetParents} from '@/utils/'
import {
  getAllRights
} from '@/utils/index'

const state = {
  userinfo: null,
  rights: [],
  routes: []
}

const getters = {
  isLogin(state) {
    return state && state.userinfo !== null
  },
  getUserinfo(state) {
    return state && state.userinfo ? state.userinfo : null
  },
  getToken(state) {
    return state && state.userinfo && state.userinfo.token ? state.userinfo.token : null
  }
}

const mutations = {
  login(state, info) {
    state.userinfo = info
  },
  logout(state) {
    state.userinfo = null
    state.rights = []
    state.routes = []
  },
  setRights(state, rights) {
    state.rights = rights
  },
  setRoutes(state, routes) {
    state.routes = routes
  }
}

// const getParent = (data, target) => {
//   const get = (children, target, record = []) => (
//     children.reduce((result, { key, children: innerChildren }) => {
//       if (key === target) {
//         return [...record, target]
//       }
//       if (innerChildren) {
//         return [...result, ...get(innerChildren, target, [...record, key])]
//       }
//       return result
//     }, []))

//   return get(data, target).join('-')
// }

const actions = {
  async getRights({
    commit
  }) {
    const {
      err,
      res
    } = await getUserRight()
    if (!err) {
      if (res.success) {
        const rights = res.data;
        const finalRights = getAllRights(rights);
        commit('setRights', finalRights)
      }
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
