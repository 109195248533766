/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-04-02 14:36:18
 * @LastEditTime: 2020-08-06 20:33:23
 */
import store from '@/store/index'
// 开发插件的方式；定义指令；第一个参数是Vue 构造器，第二个参数是可选的选项对象
function install(Vue, options = {}) {
  Vue.directive(options.name || 'auth', {
    inserted(el, binding) {
      if (!store.state.user.rights.includes(binding.value)) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  });
}

export default { install };
