/*
 * @Description: 入口文件
 * @Date: 2019-12-21 16:58:39
 * @LastEditTime: 2020-09-18 17:07:21
 * @LastEditors: 矢车
 */
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import antd from 'ant-design-vue';
import "babel-polyfill"
import App from './App.vue'
import ECharts from 'vue-echarts'
import './assets/less/reset.less'
import './assets/less/public.less'
import 'ant-design-vue/dist/antd.less'
import router from './routes/index'
import store from './store/index'
import Auth from './directives/auth'
import Filter from './filters'
import * as custom from './filters/shop'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueDND from 'awe-dnd';
import AnotherUploader from '@/components/common/AnotherUpload';
import antdExtend from '@/components/antd-extend'
import {Image,Dialog} from 'element-ui'
import MyImage from "@/components/common/Showimg/my-image";
import KrCard from '@/components/kr-card/krCard';

Vue.use(VueCompositionAPI);
Vue.use(VueRouter);
Vue.use(Vuex);

Vue.use(VueDND);

const eventBus = {
  install(Vue) {
    Vue.prototype.$bus = new Vue()
  }
}
Vue.use(eventBus)
Vue.use(antd)
Vue.use(Auth)
Vue.use(Viewer)
Vue.use(Filter)
Vue.use(antdExtend)
Vue.use(Image)
Vue.use(Dialog)
Vue.component('MyImage', MyImage);
Vue.component(KrCard.name, KrCard);


Vue.config.productionTip = false;
Vue.config.devtools = process.env.targetEnv !== 'production';

Object.keys(custom).forEach(key => { Vue.filter(key, custom[key]) })

Vue.component('v-chart', ECharts)
Vue.component('another-uploader', AnotherUploader);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

//加载状态为complete时移除loading效果
document.onreadystatechange = completeLoading;
function completeLoading() {
  if (document.readyState == 'complete') {
    var loadingMask = document.getElementById('pageLoading');
    loadingMask.parentNode.removeChild(loadingMask);
  }
}
