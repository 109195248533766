/*
 * @Description: 工具方法
 * @Date: 2020-01-16 11:18:30
 * @LastEditTime: 2021-10-25 17:31:02
 * @LastEditors: 肖槿
 */
import store from '@/store/index'
import {
  RoleTree
} from '@/const/role'
import {
  coopModeOptions,
  pidAttrOptions,
  channelOption,
  expressStatusOptions,
  scheduleStatusOptions,
  liveTypeOptions,
} from '@/const/index';
import { goodsChannelList } from '@/const/goods-cate';

// 生成UUID
export const generateUUID = () => {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}

// 根据子权限获取父权限
export const rightsGetParents = (array, label) => {
  let stack = [];
  let going = true;
  let walker = (array, label) => {
    array.forEach(item => {
      if (!going) return;
      stack.push(item['key']);
      if (item['key'] === label) {
        going = false;
      } else if (item['children']) {
        walker(item['children'], label);
      } else {
        stack.pop();
      }
    });
    if (going) stack.pop();
  }
  walker(array, label);
  return stack
}

// 获取全部权限
export const getAllRights = (rights) => {
  let rightsAll = ['x']
  rights.forEach(i => {
    const rgp = rightsGetParents(RoleTree, i)
    rightsAll = [...rightsAll, ...rgp]
  });
  const finalRights = Array.from(new Set(rightsAll))
  return finalRights;
}

// 判断是否有权限
export const checkPer = (permissionName) => {
  if (store.state.user.rights.includes(permissionName)) {
    return true
  } else {
    return false
  }
}

// 设置编辑状态改变
export const setEditStatus = (status = 'false') => {
  window.localStorage.setItem('dataChanged', status)
}

// 字符串截取
// export const subString = (str, n) => {
//   var r = /[^\x00-\xff]/g;
//   var m;

//   if (str.replace(r, '**').length > n) {
//     m = Math.floor(n / 2);

//     for (var i = m, l = str.length; i < l; i++) {
//       if (str.substr(0, i).replace(r, '**').length >= n) {
//         return str.substr(0, i);
//       }
//     }
//   }

//   return str;
// }

// 类型检查
export const checkedType = (target) => {
  return Object.prototype.toString.call(target).slice(8, -1)
}

// 拷贝
export const deepClone = (target) => {
  let result = null
  const targetType = checkedType(target)
  if (targetType === 'Object') {
    result = {}
  } else if (targetType === 'Array') {
    result = []
  } else {
    return target
  }

  for (let i in target) {
    let value = target[i]
    if (checkedType(value) === 'Object' || checkedType(value) === 'Array') {
      result[i] = deepClone(value)
    } else {
      result[i] = value;
    }
  }
  return result
}


// 根据id生成skulist
export const skuListGen = (arr) => {
  if (arr.length === 1) {
    // let arrySon = [];
    // //最终合并成一个
    // arr.forEach((_)=>{
    //   arrySon.push(_)
    // })
    return arr[0];
    // return arrySon;
  } else { //有两个子数组就合并
    let arrySon = [];
    //将组合放到新数组中
    arr[0].forEach((_, index) => {
      arr[1].forEach((_, index1) => {
        arrySon.push([].concat(arr[0][index], arr[1][index1]));
      })
    })
    // 新数组并入原数组,去除合并的前两个数组
    arr[0] = arrySon;
    arr.splice(1, 1);
    // 递归
    return skuListGen(arr);
  }
}

// 数字input格式化
export const formatInputNumber2Decimal = (value) =>{
  const reg = /^(\d+)\.(\d\d).*$/;
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(reg, "$1.$2") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(reg, "$1.$2") : "";
  } else {
    return "";
  }
}

// 默认值
export const dftValue = (value, placeholder = '-') => {
  return value ? value : placeholder;
};

/**
 * options值换取label
 *
 * const sexOptions = [
 *  { label: '男性', value: 1 },
 *  { label: '女性', value: 2 }
 * ]
 *
 * eg: value2label(1, sexOptions) -> '男性'
 *
 * @param {*} value
 * @param {*} sourceObj
 */
export const value2label = (value, sourceObj, placeholder) => {
  const result = sourceObj.filter((item) => {
    return item.value === value;
  });
  if (result && result[0]) {
    return result[0].label;
  }
  return dftValue(value, placeholder);
}

// 合作模式
export const coopMode = (value) => {
  return value2label(value, coopModeOptions);
};

// 淘客Pid归属
export const pidAttr = (value) => {
  return value2label(value, pidAttrOptions);
};

// 发货状态
export const expressStatus = (value) => {
  return value2label(value, expressStatusOptions);
};

// 排期状态
export const scheduleStatus = (value) => {
  return value2label(value, scheduleStatusOptions);
};

// 直播类型
export const liveType = (value) => {
  return value2label(value, liveTypeOptions);
};

// 主播渠道
export const channel = (value) => {
  return value2label(value, channelOption);
};

// 商品渠道
export const goodsChannel = (value) => {
  return value2label(value, goodsChannelList);
};

// 睡眠n妙
export const sleep = (sec = 500) => {
  return new Promise(resolve => setTimeout(resolve, sec))
}