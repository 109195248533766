/*
 * @Description: app vuex
 * @Date: 2019-12-23 12:25:26
 * @LastEditTime: 2020-07-08 10:10:29
 * @LastEditors: 前胡
 */


const state = {
  isGettingGoods: false,  // 是否正在获取商品数据，主要用于专题页商品id blur 获取数据
}

const getters = {

}

const mutations = {
  // 设置是否商品在请求中
  setGettingGoodsStatus(state, status) {
    state.isGettingGoods = status
  },
}

const actions = {

}

export default {
  state,
  getters,
  mutations,
  actions
}