/*
 * @Description: Vuex
 * @Date: 2019-12-21 18:53:11
 * @LastEditTime: 2020-07-01 16:24:32
 * @LastEditors: 前胡
 */
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import user from './modules/user';
import app from './modules/app';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({ storage: window.localStorage });

export default new Vuex.Store({
  modules: { user, app },
  plugins: [vuexLocal.plugin],
});
