/*
 * @Description: 管理员相关接口
 * @Date: 2019-12-23 14:59:46
 * @LastEditTime: 2020-04-01 10:43:49
 * @LastEditors: 前胡
 */
import axios from './axios'

// 管理员登录
export const adminDoLogin = data => {
  return axios.post('/api/v1/login/', data)
}

// 管理员登录按照花名
export const adminPasswordLogin = data => {
  return axios.post('/api/operators/v1/admin-user-login/', data)
}

// 获取验证码
export const getPhoneCode = data => {
  return axios.post('/api/v1/codes/', data)
}

// 修改后台用户密码
export const updatePassword = data => {
  return axios.patch('/api/operators/v1/admin-user/update-password/', data)
}

// 当前后台用户权限列表
export const getUserRight = () => {
  return axios.get('/api/operators/v1/admin-user-rights/')
}